<template>
  <v-container fluid v-if="user" class="dashboard secondary--text text-center">
    <h1 class="default--text">{{ `Ciao ${user.firstName}` }}</h1>
    <h2 class="mb-4 mb-sm-0 default--text">{{ $t("dashboard.subtitle") }}</h2>
    <v-row no-gutters>
      <v-col cols="12" sm="6" md="4" class="pa-2">
        <category-block
          :target="category"
          position="position1"
          class="category-block category-block-1"
        />
      </v-col>
      <v-col
        cols="12"
        sm="6"
        md="4"
        v-for="(card, idx) in cards"
        :key="idx"
        class="pa-2"
      >
        <DashboardCard :cardData="card" :counter="getPoints(card.name)" />
      </v-col>
    </v-row>

    <div
      v-if="$platform_is_cordova && isBetaTester"
      class="mt-10 development-link front-weight-bold"
    >
      <a :href="developmentLink">Apri versione di test</a>
    </div>
  </v-container>
</template>
<style lang="scss">
.dashboard {
  h2 {
    font-size: 20px;
    font-weight: normal;
    line-height: 30px;
  }
  .fidelityPoints {
    background-color: #000;
    .v-card__title {
      color: $white !important;
    }
    .v-card__subtitle {
      color: $white !important;
      .counter {
        font-size: 35px;
        color: var(--v-primary-base);
        font-weight: bold;
      }
    }
  }
  // .donatePoints ,
  .donateTree {
    .v-card__title {
      color: $white !important;
    }
    .v-card__subtitle {
      color: $white !important;
      .counter {
        font-size: 35px;
        color: $white;
        font-weight: bold;
        margin-top: 10px;
      }
    }
    a,
    .v-icon {
      color: $white !important;
    }
  }

  .donateTree {
    background-image: url("/img_layout/nature/we-love-trees-texture.png");
    background-size: cover;
  }
  // .donatePoints {
  //   background-color: #da1b5c;
  // }
}
</style>
<script>
// @ is an alias to /src
import DashboardCard from "@/components/profile/DashboardCard.vue";

import category from "~/mixins/category";

import RegistrationService from "~/service/userService";
import addressService from "~/service/addressService";

import { mapActions, mapGetters } from "vuex";

export default {
  name: "Dashboard",
  components: { DashboardCard },
  mixins: [category],
  data() {
    return {
      user: null,
      favoriteWarehouse: {}
    };
  },
  computed: {
    ...mapGetters({
      isBetaTester: "cart/isBetaTester"
    }),
    cards() {
      return [
        {
          name: "fidelityPoints",
          title: "dashboard.fidelityPoints.title",
          description: "",
          label: "dashboard.fidelityPoints.label",
          icon: "",
          routeName: "Awards",
          class: "fidelityPoints",
          link: "dashboard.fidelityPoints.link",
          isColumn: true,
          navigation: true
        },
        {
          name: "premialí",
          title: "dashboard.premiali.title",
          description: "dashboard.premiali.description",
          icon: "premiali",
          routeName: "BookedAwards",
          link: "dashboard.premiali.link",
          navigation: true
        },
        {
          name: "createOffer",
          title: "dashboard.createOffer.title",
          description: "dashboard.createOffer.description",
          icon: "offerta",
          routeName: "CreateOffer",
          link: "dashboard.createOffer.link",
          navigation: true
        },
        {
          name: "voucher",
          title: "dashboard.voucher.title",
          description: "dashboard.voucher.description",
          icon: "buoni",
          routeName: "ProfileGift",
          link: "dashboard.voucher.link",
          navigation: true
        },
        {
          name: "profile",
          title: "dashboard.profile.title",
          description: "dashboard.profile.description",
          icon: "profile",
          routeName: "ProfileUpdate",
          link: "dashboard.profile.link",
          navigation: true
        },
        {
          name: "orders",
          title: "dashboard.orders.title",
          description: "dashboard.orders.description",
          icon: "cart",
          routeName: "Orders",
          link: "dashboard.orders.link",
          navigation: true
        },
        {
          name: "lists",
          title: "dashboard.lists.title",
          description: "dashboard.lists.description",
          icon: "heart",
          routeName: "Lists",
          link: "dashboard.lists.link",
          navigation: true
        },
        {
          name: "favoriteWarehouse",
          title: "dashboard.favoriteWarehouse.title",

          icon: "indirizzi",
          routeName: "WarehouseDetail",
          params: {
            slug:
              this.favoriteWarehouse != null
                ? this.favoriteWarehouse
                : this.user?.defaultStoreAddress?.addressId
          },
          link: "dashboard.addresses.link",
          navigation: true
        },
        {
          name: "donateTree",
          title: "dashboard.donateTree.title",
          description: "dashboard.donateTree.description",
          icon: "",
          routeName: "AwardsDetail",
          params: { slug: "donazione-100-punti-albero2" },

          class: "donateTree",
          link: "dashboard.donateTree.link",
          isColumn: true,
          navigation: true
        },
        {
          name: "donate",
          title: "dashboard.donatePoints.title",
          description: "dashboard.donatePoints.description",
          icon: "give",
          routeName: "Donate",
          class: "donatePoints",
          link: "dashboard.donatePoints.link",
          navigation: true
        },
        {
          name: "logout",
          title: "dashboard.logout.title",
          description: "dashboard.logout.description",
          icon: "chevronRight",
          routeName: "doLogout",
          navigation: false
        }
      ];
    },
    developmentLink() {
      // eslint-disable-next-line no-undef
      let platform = device ? device.platform.toLowerCase() : "ios";
      return "/mobile/develop/" + platform + "/index.html";
    }
  },
  methods: {
    getPoints(cardName) {
      if (cardName === "fidelityPoints")
        // return this.user && this.user.userPoints.CAMI.value;
        return this.user && this.user.userPoints.CAMI.value === 0
          ? 0
          : this.user && this.user.userPoints.CAMI.value;
      if (cardName === "donateTree") return 100;
    },
    async fetchUserData() {
      this.user = await RegistrationService.getUserDetail();
      if (this.user?.defaultStoreAddress?.addressId) {
        let allWh = await addressService.findWarehouse();

        this.favoriteWarehouse = allWh.warehouses.filter(
          wh =>
            this.user?.defaultStoreAddress?.addressId == wh.address.addressId
        );
        this.favoriteWarehouse = this.favoriteWarehouse[0].warehouseId;
      }
    },
    ...mapActions({
      logout: "cart/doLogout"
    }),
    async doLogout() {
      await this.logout();
      this.$router.push({
        name: "Home",
        path: "/"
      });
    }
  },
  async created() {
    await this.fetchUserData();
  }
};
</script>
