<template>
  <v-card
    :class="[cardData.class, { row: isCordova && !cardData.isColumn }]"
    elevation="0"
    :to="isCordova ? route : null"
    v-if="cardData.navigation"
  >
    <div
      no-gutters
      v-if="cardData.icon"
      class="mt-3 ml-4"
      :class="{ row: !isCordova || (isCordova && cardData.isColumn) }"
    >
      <v-icon color="primary" class="primary lighten-2 pa-2" large>{{
        `$${cardData.icon}`
      }}</v-icon>
    </div>
    <v-card-title>
      {{ $t(cardData.title) }}
    </v-card-title>
    <v-card-subtitle class="mt-1">
      <div
        class="description text-body-2 text-left"
        v-if="cardData.description"
      >
        {{ $t(cardData.description) }}
      </div>
      <!-- handling counter for donateTree -->
      <div
        v-if="cardData.name === 'donateTree'"
        class="text-left counter"
        v-html="`${counter} ${$t('dashboard.points')}`"
      />
      <div class="text-body-1 text-left mt-2" v-if="cardData.label">
        <div v-if="counter" class="d-flex flex-row align-center">
          <span class="counter">{{ counter }}</span>
          <span class="label ml-2" v-html="$t(cardData.label)" />
        </div>
        <span v-else class="label">{{ $t(cardData.label) }}</span>
      </div>
    </v-card-subtitle>
    <v-card-actions class="d-flex justify-end">
      <router-link :to="route">{{ $t(cardData.link) }}</router-link>

      <v-icon color="primary">$chevronRight</v-icon>
    </v-card-actions>
  </v-card>
  <v-card v-else @click.stop="doLogout" :class="cardData.class">
    <div class="d-flex flex-row h-100 w-100">
      <div class="flex-shrink align-start">
        <v-icon color="primary" class="primary lighten-2 pa-2 h-100" large>
          {{ `$${cardData.icon}` }}
        </v-icon>
      </div>
      <div class="flex-grow-1 align-self-end">
        <v-card-title
          class="pb-sm-2 mb-sm-2 pb-md-10 mb-md-10"
          :class="cardData.icon ? 'pt-1' : 'mt-3'"
        >
          {{ $t(cardData.title) }}
        </v-card-title>
      </div>
    </div>
  </v-card>
</template>

<style scoped lang="scss">
.is-cordova {
  .v-card {
    height: 64px;
    border: 1px solid var(--v-accent-base);
    margin: 0px !important;
    .v-card__title {
      font-size: 16px;
    }
    .v-card__subtitle {
      font-size: 17px;
      display: none;
    }
    .description {
      font-weight: 600;
    }
    .v-icon {
      font-size: 24px !important;
      border-radius: $border-radius-root;
    }
    .v-card__actions {
      position: absolute;
      bottom: 10px;
      right: 1px;
      a {
        display: none;
        color: var(--v-primary-base);
        text-decoration: none;
        font-weight: bold;
        font-size: 16px;
      }
    }
  }
  .fidelityPoints {
    @media #{map-get($display-breakpoints, 'sm-only')} {
      height: 100%;
    }
    .v-card__title {
      margin-top: 0px !important;
      padding: 0px 16px;
    }
    .v-card__subtitle {
      display: flex !important;
      margin-top: 0px !important;
      .counter {
        font-size: 20px;
      }
    }
  }
  .donateTree {
    .v-card__title {
      margin-top: 18px !important;
      padding: 0px 16px;
    }
    .v-card__subtitle {
      margin-top: 0px !important;
      .counter {
        font-size: 20px;
      }
    }
  }
  .logout {
    display: flex;
  }
}
.v-card {
  height: 180px;
  border: 1px solid var(--v-accent-base);
  .v-card__title {
    font-size: 28px;
  }
  .description {
    font-weight: 600;
  }
  .v-icon {
    font-size: 24px !important;
    border-radius: $border-radius-root;
  }
  .v-card__actions {
    position: absolute;
    bottom: 4px;
    right: 1px;
    a {
      color: var(--v-primary-base);
      text-decoration: none;
      font-weight: bold;
      font-size: 16px;
    }
  }
}
.logout {
  display: none;
}
</style>

<script>
import { mapActions } from "vuex";
export default {
  name: "DashboardCard",
  props: {
    cardData: { type: Object, required: true },
    counter: { type: Number, required: false, default: 0 }
  },
  computed: {
    route() {
      return {
        name: this.cardData.routeName,
        params: this.cardData.params
      };
      // return this.cardData.slug
      //   ? { name: "AwardsDetail", params: { slug: this.cardData.slug } }
      //   : { name: this.cardData.routeName };
    }
  },
  methods: {
    ...mapActions({
      logout: "cart/doLogout"
    }),
    async doLogout() {
      await this.logout();
      this.$router.push({
        name: "HomeApp",
        path: "/app"
      });
    }
  }
};
</script>
